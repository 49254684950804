const skill = require("./skill");
//change here to your skills.

module.exports = [
  skill("HTML / CSS", 2011, 11),
  skill("Bootstrap", 2012, 1),
  skill("Cutestrap", 2019, 12),
  skill("JavaScript", 2011, 11),
  skill("Express", 2014, 1),
  skill("React.js", 2015, 1),
  skill("jQuery", 2012, 1),
  skill("TypeScript", 2019, 10),
  skill("Apollo Client", 2019, 10),
  skill("React Native", 2019, 10),
  skill("Ruby", 2014, 1),
  skill("Ruby on Rails", 2014, 3),
  skill("Devise", 2020, 4),
  skill("RSpec", 2019, 4),
  skill("factorybot", 2019, 4),
  skill("RMagick", 2019, 4),
  skill("Nokogiri", 2017, 4),
  skill("Capybara", 2017, 4),
  skill("Swift", 2015, 1),
  skill("Alamofire", 2015, 10),
  skill("TestFlight", 2014, 11),
  skill("Codable", 2018, 10),
  skill("Realm Swift", 2015, 10),
  skill("Java", 2013, 1),
  skill("Python", 2014, 1),
  skill("Tensorflow", 2018, 1),
  skill("Keras", 2018, 1),
  skill("Scikit-learn", 2018, 1),
  skill("Shell Script", 2018, 1),
  skill("Rust", 2019, 1),
  skill("Golang", 2018, 10),
  skill("Golem", 2018, 10),
  skill("Gin", 2018, 10),
  skill("Gorm", 2018, 10),
  skill("Heroku", 2020, 10),
  skill("Firebase", 2018, 10),
  skill("Firebase Cloud Functions", 2021, 2),
  skill("Firebase Authentication", 2018, 10),
  skill("Realtime Database", 2018, 10),
  skill("AWS Lambda", 2016, 4),
  skill("AWS API Gateway", 2016, 4),
  skill("AWS DynamoDB", 2016, 4),
  skill("MySQL", 2014, 4),
  skill("Postgresql", 2018, 1),
  skill("SQLite", 2014, 3),
  skill("Cent OS", 2014, 4),
  skill("Amazon Linux", 2019, 4),
  skill("Ubuntu", 2020, 1),
  skill("Unity", 2015, 1),
];
